// @codingStandardsIgnoreFile
'use strict'

import ExpandableMenu from 'assets/core/js/module/expandableMenu'
import favoriteMenu from 'assets/core/js/module/favorite/menu'

export default {
  initMenus: function () {
    const expandableMenus = []

    document.querySelectorAll<HTMLElement>('.header .dca-expandable__menu').forEach(function (el) {
      expandableMenus.push(new ExpandableMenu(el))
    })

    if (document.getElementById('header-nav-toggle')) {
      document.getElementById('header-nav-toggle')?.addEventListener('click', function () {
        document.getElementById('header-nav')?.classList.toggle('nav--hidden')
      })
    }

    favoriteMenu()
  },
}
